import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app-core/app.module';
import { environment } from './environments/environment';

import 'zone.js/dist/zone';

if (environment.production) {
  enableProdMode();
}

function addGtmScript(gtmId: string): void {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.innerHTML = `
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${gtmId}');
  `;
  document.head.appendChild(script);
}

// function addAxeptioScript(clientId: string): void {
//   const script = document.createElement('script');
//   script.type = 'text/javascript';
//   script.async = true;
//   script.src = 'https://static.axept.io/sdk.js';
//   script.onload = () => {
//     const axeptioSettings = {
//       clientId: clientId,
//       cookiesVersion: 'voyages inglard-fr-EU',
//       googleConsentMode: {
//         default: {
//           analytics_storage: 'denied',
//           ad_storage: 'denied',
//           ad_user_data: 'denied',
//           ad_personalization: 'denied',
//           wait_for_update: 500
//         }
//       }
//     };
//     (window as any).axeptioSettings = axeptioSettings;

//     if ((window as any).Axeptio) {
//       (window as any).Axeptio.init(axeptioSettings);
//     } else {
//       console.warn('Axeptio SDK not found after script load');
//     }
//   };
//   document.head.appendChild(script);
// }

document.addEventListener('DOMContentLoaded', () => {
  addGtmScript(environment.gtmId);
  // addAxeptioScript('6735ccd6de5ce7b7854b32df');
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
